


.left-description {

}

.left-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.left-checkbox img {
    width: 27px;
    height: 23px;
}

.left-checkbox p {
    margin-left: 10px;
    font-size: 18px;
    color: #7f7f7f;
}


footer {
    margin-top: 20px;
    padding: 20px;
}

footer blockquote {
    font-style: italic;
}

footer p {
    margin-top: 10px;
    font-weight: bold;
}

@tailwind base;
@tailwind components;
@tailwind utilities;